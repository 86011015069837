import React from 'react'
import { Link } from 'gatsby'

import SiteLayout from '../layout/SiteLayout'
import SEO from '../components/SEO'
import Container from '../components/Container'
import { Title, TitleContainer } from '../components/Title'

const TermsOfUse = () => (
  <SiteLayout>
    <SEO title="Terms of use" />
    <TitleContainer>
      <Title>Terms of use</Title>
    </TitleContainer>
    <Container>
      <section className="terms">
        <h1>Terms of use</h1>
        <p>
          These Website Terms of Use (“The Terms”), together with any and all
          other documents referred to herein, set out the Terms of use under
          which you may use our website, www.airaccounting.com (“Our Website”).
          Please read these Terms carefully and ensure that you understand them.
          Your agreement to comply with and be bound by these Terms is deemed to
          occur upon your first use of Our Website. If you do not agree to
          comply with and be bound by these Terms of Use, you must stop using
          Our Website immediately.
        </p>
        <ol>
          <li>
            Definitions and Interpretation
            <ol>
              <li>
                In these Terms, unless the context otherwise requires, the
                following expressions have the following meanings:
                <div className="mt-3">
                  <h5>Content</h5>
                  <p>
                    means any and all text, images, audio, video, scripts, code,
                    software, databases and any other form of information
                    capable of being stored on a computer that appears on, or
                    forms part of, Our Website;
                  </p>
                  <h5>User</h5>
                  <p>means a user of the Website;</p>
                  <h5>User Content</h5>
                  <p>
                    means any content submitted to Our Website by Users; and
                  </p>
                  <h5>AirAccounting/We/Us/Our</h5>
                  <p>
                    means Air Accounting Limited, a company registered in
                    England and Wales under company number, 10256980, whose
                    registered address is Dudley Court South, The Waterfront,
                    Brierley Hill, West Midlands, DY5 1XN.
                  </p>
                </div>
              </li>
            </ol>
          </li>
          <li>
            Information About Us
            <ol>
              <li>
                Our Website, www.airccounting.com is owned and operated by Air
                Accounting Limited a limited company registered in England and
                Wales under company number 10256980, whose registered address is
                Dudley Court South, The Waterfront, Brierley Hill, West
                Midlands, DY5 1XN.
              </li>
              <li>
                Our Website contains material which is owned by or licensed to
                us. This material includes, but is not limited to, the design,
                layout, look, appearance and graphics. Reproduction is
                prohibited.
              </li>
            </ol>
          </li>
          <li>
            Access to Our Website
            <ol>
              <li>Access to Our Website is free of charge.</li>
              <li>
                It is your responsibility to make any and all arrangements
                necessary in order to access Our Website.
              </li>
              <li>
                Access to Our Website is provided “as is” and on an “as
                available” basis. We may alter, suspend or discontinue Our
                Website (or any part of it) at any time and without notice. We
                will not be liable to you in any way if Our Website (or any part
                of it) is unavailable at any time and for any period.
              </li>
            </ol>
          </li>
          <li>
            Registration, Passwords and Data Security
            <ol>
              <li>
                You may access many areas of Our Website without registering
                your details with us. However certain areas are only open to you
                if you register. Please see our{' '}
                <Link to="/privacy-policy">“Privacy Policy”</Link> as this
                policy governs registration together with data security and use
                of passwords.
              </li>
              <li>
                From time to time, Our Website may contain links to other
                websites. Unless expressly stated, these sites are not under Our
                control. We neither assume or accept responsibility or liability
                for the content of third-party sites. The inclusion of a link to
                another site on Our Website is for information only and does not
                imply any endorsement of the sites themselves or of those in
                control of them.
              </li>
            </ol>
          </li>
          <li>
            Intellectual Property Rights
            <ol>
              <li>
                All Content included on Our Website and the copyright and other
                intellectual property rights subsisting in that Content, unless
                specifically labelled otherwise, belongs to or has been licensed
                by Us. All Content (including User Content) is protected by
                applicable United Kingdom and international intellectual
                property laws and treaties.
              </li>
              <li>
                You may not reproduce, copy, distribute, sell, rent,
                sub-licence, store, or in any other manner re-use Content from
                Our Site unless given express written permission to do so by Us.
              </li>
              <li>
                Our status as the owner and author of the Content on Our Website
                (or that of identified licensors, as appropriate) must always be
                acknowledged.
              </li>
            </ol>
          </li>
          <li>
            Disclaimers
            <ol>
              <li>
                Nothing on Our Website constitutes advice on which you should
                rely. It is provided for general information purposes only.
                While we give every effort to ensure its accuracy, information
                contained on Our Website may not be complete, may have changed
                or may not be relevant to or appropriate for your circumstances.
                Readers of Our Website must not use the information without
                seeking professional advice. The information is not intended as
                legal, accounting, financial or tax advice. Air Accounting
                Limited, related organisations, employees and directors are not
                liable to you or anyone else for decisions or actions resulting
                from placing reliance on the information contained on Our
                Website.
              </li>
            </ol>
          </li>
          <li>
            Our Liability
            <ol>
              <li>
                The provisions of this Clause 7 apply only to the use of Our
                Website and not to our services, which is governed separately.
              </li>
              <li>
                To the fullest extent permissible by law, We accept no liability
                to any User for any loss or damage, whether foreseeable or
                otherwise, in contract, tort (including negligence), for breach
                of statutory duty, or otherwise, arising out of or in connection
                with the use of (or inability to use) Our Website or the use of
                or reliance upon any Content (including User Content) included
                on Our Website.
              </li>
              <li>
                To the fullest extent permissible by law, We exclude all
                representations, warranties, and guarantees (whether express or
                implied) that may apply to Our Website or any Content included
                on Our Website.
              </li>
              <li>
                We exercise all reasonable skill and care to ensure that Our
                Website is free from viruses and other malware. However, subject
                to sub-Clause 8.2, We accept no liability for any loss or damage
                resulting from a virus or other malware, a distributed denial of
                service attack, or other harmful material or event that may
                adversely affect your hardware, software, data or other material
                that occurs as a result of your use of Our Website (including
                the downloading of any Content from it) or any other site
                referred to on Our Website.
              </li>
              <li>
                We neither assume nor accept responsibility or liability arising
                out of any disruption or non-availability of Our Website
                resulting from external causes including, but not limited to,
                ISP equipment failure, host equipment failure, communications
                network failure, natural events, acts of war, or legal
                restrictions and censorship.
              </li>
              <li>
                Nothing in these Website Terms of Use excludes or restricts Our
                liability for fraud or fraudulent misrepresentation, for death
                or personal injury resulting from negligence, or for any other
                forms of liability which cannot be excluded or restricted by
                law.
              </li>
            </ol>
          </li>
          <li>
            Viruses, Malware and Security
            <ol>
              <li>
                We exercise all reasonable skill and care to ensure that Our
                Website is secure and free from viruses and other malware.
              </li>
              <li>
                You are responsible for protecting your hardware, software, data
                and other material from viruses, malware, and other internet
                security risks.
              </li>
              <li>
                You must not deliberately introduce viruses or other malware, or
                any other material which is malicious or technologically harmful
                either to or via Our Website.
              </li>
              <li>
                You must not attempt to gain unauthorised access to any part of
                Our Website, the server on which Our Website is stored, or any
                other server, computer, or database connected to Our Website.
              </li>
              <li>
                You must not attack Our Website by means of a denial of service
                attack, a distributed denial of service attack, or by any other
                means.
              </li>
              <li>
                By breaching the provisions of sub-Clauses 8.3 to 8.5 you may be
                committing a criminal offence under the Computer Misuse Act
                1990. Any and all such breaches will be reported to the relevant
                law enforcement authorities and We will cooperate fully with
                those authorities by disclosing your identity to them. Your
                right to use Our Website will cease immediately in the event of
                such a breach.
              </li>
            </ol>
          </li>
          <li>
            Acceptable Usage Policy
            <ol>
              <li>
                You may only use Our Website in a manner that is lawful and that
                complies with the provisions of this Clause 9. Specifically:
                <ol>
                  <li>
                    you must ensure that you comply fully with any and all
                    local, national or international laws and/or regulations;
                  </li>
                  <li>
                    you must not use Our Website in any way, or for any purpose,
                    that is unlawful or fraudulent;
                  </li>
                  <li>
                    you must not use Our Website to knowingly send, upload, or
                    in any other way transmit data that contains any form of
                    virus or other malware, or any other code designed to
                    adversely affect computer hardware, software, or data of any
                    kind; and
                  </li>
                  <li>
                    you must not use Our Website in any way, or for any purpose,
                    that is intended to harm any person or persons in any way.
                  </li>
                </ol>
              </li>
              <li>
                When submitting User Content (or communicating in any other way
                using Our Website), you must not submit, communicate or
                otherwise do anything that:
                <ol>
                  <li>
                    is obscene, deliberately offensive, hateful or otherwise
                    inflammatory;
                  </li>
                  <li>promotes violence;</li>
                  <li>promotes or assists in any form of unlawful activity;</li>
                  <li>
                    discriminates against, or is in any way defamatory of, any
                    person, group or class of persons, race, sex, religion,
                    nationality, disability, sexual orientation or age;
                  </li>
                  <li>
                    is intended or otherwise likely to threaten, harass, annoy,
                    alarm, inconvenience, upset, or embarrass another person;
                  </li>
                  <li>is calculated or is otherwise likely to deceive;</li>
                  <li>
                    is intended or otherwise likely to infringe (or threaten to
                    infringe) another person’s right to privacy or otherwise
                    uses their personal data in a way that you do not have a
                    right to;
                  </li>
                  <li>
                    misleadingly impersonates any person or otherwise
                    misrepresents your identity or affiliation in a way that is
                    calculated to deceive (obvious parodies are not included
                    within this definition provided that they do not fall within
                    any of the other provisions of this sub-Clause 9.2);
                  </li>
                  <li>
                    implies any form of affiliation with Us where none exists;
                  </li>
                  <li>
                    infringes, or assists in the infringement of, the
                    intellectual property rights (including, but not limited to,
                    copyright, patents, trade marks and database rights) of any
                    other party; or
                  </li>
                  <li>
                    is in breach of any legal duty owed to a third party
                    including, but not limited to, contractual duties and duties
                    of confidence.
                  </li>
                </ol>
              </li>
              <li>
                We reserve the right to suspend or terminate your access to Our
                Website if you materially breach the provisions of this Clause 9
                or any of the other provisions of these Website Terms of Use.
                Specifically, We may take one or more of the following actions:
                <ol>
                  <li>
                    suspend, whether temporarily or permanently, your Account
                    and/or your right to access Our Website;
                  </li>
                  <li>
                    remove any User Content submitted by you that violates this
                    Acceptable Website Usage Policy;{' '}
                  </li>
                  <li>issue you with a written warning;</li>
                  <li>
                    take legal proceedings against you for reimbursement of any
                    and all relevant costs on an indemnity basis resulting from
                    your breach;
                  </li>
                  <li>take further legal action against you as appropriate;</li>
                  <li>
                    disclose such information to law enforcement authorities as
                    required or as We deem reasonably necessary; and/or
                  </li>
                  <li>
                    any other actions which We deem reasonably appropriate (and
                    lawful).
                  </li>
                </ol>
              </li>
              <li>
                We hereby exclude any and all liability arising out of any
                actions (including, but not limited to those set out above) that
                We may take in response to breaches of these Website Terms of
                Use.
              </li>
            </ol>
          </li>
          <li>
            Cookies
            <ol>
              <li>
                Use of Our Website is also governed by Our Privacy Policy,
                available from <Link to="/privacy-policy">here</Link>. These
                policies are incorporated into these Website Terms of Use by
                this reference.
              </li>
            </ol>
          </li>
          <li>
            Changes to these Terms of Use
            <ol>
              <li>
                We may alter these Website Terms of Use at any time. Any such
                changes will become binding on you upon your first use of Our
                Website after the changes have been implemented. You are
                therefore advised to check this page from time to time.
              </li>
              <li>
                In the event of any conflict between the current version of
                these Website Terms of Use and any previous version(s), the
                provisions current and in effect shall prevail unless it is
                expressly stated otherwise.
              </li>
            </ol>
          </li>
          <li>
            Contacting Us
            <ol>
              <li>
                To contact Us, please email Us at{' '}
                <a href="mailto:hello@airaccounting.com">
                  hello@airaccounting.com
                </a>
                .
              </li>
            </ol>
          </li>
          <li>
            Data Protection
            <ol>
              <li>
                All personal information that We may use will be collected,
                processed, and held in accordance with the provisions of EU
                Regulation 2016/679 General Data Protection Regulation (“GDPR”)
                and your rights under the GDPR.
              </li>
              <li>
                For complete details of Our collection, processing, storage, and
                retention of personal data including, but not limited to, the
                purpose(s) for which personal data is used, the legal basis or
                bases for using it, details of your rights and how to exercise
                them, and personal data sharing (where applicable), please refer
                to <Link to="/privacy-policy">Our Privacy Policy</Link>.
              </li>
            </ol>
          </li>
          <li>
            Law and Jurisdiction
            <ol>
              <li>
                These Terms and Conditions, and the relationship between you and
                Us (whether contractual or otherwise) shall be governed by and
                construed in accordance with the law of England & Wales,
                Northern Ireland and Scotland.
              </li>
              <li>
                Any disputes arising out of such use of Our Website, the
                relationship between you and Us, or any matters arising
                therefrom or associated therewith (whether contractual or
                otherwise) shall be subject to the laws of the courts of
                England, Wales, Northern Ireland and Scotland.
              </li>
            </ol>
          </li>
        </ol>
      </section>
    </Container>
  </SiteLayout>
)

export default TermsOfUse
